const routerViewMap = {
    Home: () => import('@/views/Home/HomeIndex'),
    Dashboard: () => import('@/views/Home/Dashboard/Dashboard'),
    MainTodo: () => import('@/views/Home/MainTodo/Todo'),
    approvalDetail: () => import('@/views/Mt/Approval/ApprovalDetail.vue'),
    /*  */
    Equipment: () => import('@/views/Equipment/EquipmentIndex'),
    Project: () => import('@/views/Project/ProjectIndex'),
    Storage: () => import('@/views/Project/SteelStorage/Storage/Storage'),
    Location: () => import('@/views/Project/SteelStorage/Storage/Location'),
    Stock: () => import('@/views/Project/SteelStorage/Stock/Stock'),
    StockLogIn: () => import('@/views/Project/SteelStorage/Stock/Stock'),
    StockLogOut: () => import('@/views/Project/SteelStorage/Stock/Stock'),
    StockApply: () => import('@/views/Project/SteelStorage/Stock/Stock'),
    ClientApply: () => import('@/views/Project/SteelStorage/Stock/Stock'),
    AlreadyStock: () => import('@/views/Project/SteelStorage/Stock/StockList'),
    StockStatistics: () => import('@/views/Project/SteelStorage/Stock/StockStatistics'),
    StockDeliveryApply: () => import('@/views/Project/SteelStorage/Storage/StockApplyList'),
    // 煤炭仓库
    MtStorageSetup: () => import('@/views/Project/MtStorage/Storage/Storage'),
    MtPortSetup: () => import('@/views/Project/MtStorage/Storage/Port'),
    MtLocation: () => import('@/views/Project/MtStorage/Storage/Location'),
    MtStockLogIn: () => import('@/views/Project/MtStorage/Stock/Stock'),
    MtStockLogOut: () => import('@/views/Project/MtStorage/Stock/Stock'),
    MtStockApply: () => import('@/views/Project/MtStorage/Stock/Stock'),
    MtStockManage: () => import('@/views/Project/MtStorage/Stock/StockManage'),
    MtStockIn: () => import('@/views/Project/MtStorage/Stock/Stock'),
    MtStockOut: () => import('@/views/Project/MtStorage/Stock/Stock'),
    stockMoveList: () => import('@/views/Project/MtStorage/StockMove/StockMoveList'),
    Library: () => import('@/views/Project/MtStorage/Storage/Library'),

    /*  */
    System: () => import('@/views/System/SystemIndex'),
    User: () => import('@/views/System/User/User'),
    Menu: () => import('@/views/System/Menu/Menu'),
    Role: () => import('@/views/System/Role/Role'),
    Back: () => import('@/views/System/Back/Back'),
    MtBack: () => import('@/views/System/Back/MtBack'),

    /* 日志 */
    Log: () => import('@/views/Log/LogIndex'),
    LoginInfor: () => import('@/views/Log/logininfor/LoginInfor'),
    OperLog: () => import('@/views/Log/OperLog/OperLog'),
    /* 采销管理 */
    Business: () => import('@/views/Business/BusinessIndex'),
    Projects: () => import('@/views/Business/Project/Project'),
    ProjectDetail: () => import('@/views/Business/Project/ProjectDetail'),
    Sole: () => import('@/views/Business/Purchase/Purchase'),
    Purchase: () => import('@/views/Business/Purchase/Purchase'),
    PurchaseDetail: () => import('@/views/Business/Purchase/Detail'),
    SubjectTemplate: () => import('@/views/Business/SubjectTemplate'),
    StorageLogistics: () => import('@/views/Business/StorageLogistics/StorageLogistics'),
    StorageLogisticsDetail: () => import('@/views/Business/StorageLogistics/StorageLogisticsDetail'),
    /* 煤炭管理 */
    MtProjects: () => import('@/views/Mt/MtProject/MtProject'),
    MtProjectDetail: () => import('@/views/Mt/MtProject/MtProjectDetail'),
    MtPurchase: () => import('@/views/Mt/MtBusiness/MtPurchase'),
    MtSole: () => import('@/views/Mt/MtBusiness/MtPurchase'),
    MtPurchaseDetail: () => import('@/views/Mt/MtBusiness/Detail'),
    /* 客户管理 */
    Consumer: () => import('@/views/Consumer/ConsumerIndex'),
    ConsumerList: () => import('@/views/Consumer/Consumer/Consumer'),
    ConsumerDetail: () => import('@/views/Consumer/Consumer/ConsumerDetail'),
    // 客户端页面
    Client: () => import('@/views/Client/ClientIndex'),
    ClientStock: () => import('@/views/Client/ClientIndex'),
    ClientStockList: () => import('@/views/Client/ClientStock/StockList'),
    ClientStorageList: () => import('@/views/Client/ClientStock/StorageList'),
    ClientApproval: () => import('@/views/Client/ClientStock/ClientApproval'),
    Detail: () => import('@/views/Client/System/Detail'),
    Jump: () => import('@/views/Client/System/Jump'),
    ResourceDetail: () => import('@/views/Client/Contract/ResourceDetail'),
    ContractManagement: () => import('@/views/Client/Contract/ContractList'),
    Capital: () => import('@/views/Client/Finance/Capital'),
    Invoice: () => import('@/views/Client/Finance/Invoice'),
    ClientDashboard: () => import('@/views/Client/Dashboard/Dashboard'),
    ClientUser: () => import('@/views/Client/System/User'),
    ContractSign: () => import('@/views/Client/Contract/ContractSign'),
    // ContractSingList: () => import('@/views/Client/Contract/ContractSingList'),
    // 审批模块
    Approval: () => import('@/views/Approval/Approval'),
    ApprovalAllList: () => import('@/views/Approval/ApprovalList/ApprovalList'),
    ApprovalLunch: () => import('@/views/Approval/ApprovalList/ApprovalList'),
    ApprovalSuccess: () => import('@/views/Approval/ApprovalList/ApprovalList'),
    ApprovalFail: () => import('@/views/Approval/ApprovalList/ApprovalList'),
    ApprovalOwn: () => import('@/views/Approval/ApprovalList/ApprovalList'),
    ApprovalTemplateList: () => import('@/views/Approval/ApprovalTemplateList/ApprovalTemplateList'),

    // ncc管理
    Ncc: () => import('@/views/Ncc/NccIndex'),
    NccLogList: () => import('@/views/Ncc/NccLogList/NccLogList'),

    // 台账管理
    Ledger: () => import('@/views/Ledger/LedgerIndex'),
    OperationLedger: () => import('@/views/Ledger/LedgerList/OperationLedger'),
    SaleOrdersLedger: () => import('@/views/Ledger/LedgerList/SaleOrdersLedger'),
    OverDateLedger: () => import('@/views/Ledger/LedgerList/OverDateLedger'),
    MtOverDateLedger: () => import('@/views/Ledger/LedgerList/MtOverDateLedger'),
    MtStorageLedger: () => import('@/views/Ledger/LedgerList/MtStorageLedger'),

    // 合同管理
    Contract: () => import('@/views/Contract/ContractIndex'),
    contractList: () => import('@/views/Contract/ContractList/ContractList'),

    // 财务管理
    mtPurchase: () => import('@/views/MtFinancialManagement/mtPurchase'),
    mtSale: () => import('@/views/MtFinancialManagement/mtSale'),
    mtFinancialDetail: () => import('@/views/MtFinancialManagement/mtFinancialDetail'),
    FinancialDetail: () => import('@/views/MtFinancialManagement/FinancialDetail'),


    // 发票管理
    mtInvoiceList: () => import('@/views/Invoice/MtInvoice/InvoiceList.vue'),
    InvoiceList: () => import('@/views/Invoice/Invoice/InvoiceList.vue'),

    // 客户端煤炭合同管理
    MtContractManagement: () => import('@/views/Mt/Client/Contract/MtContractList')

}
export default (key) => {
    return routerViewMap[key] || null
}
